import SaveIcon from "@mui/icons-material/Save"
import LoadingButton from "@mui/lab/LoadingButton"
import { Grid, styled } from "@mui/material"
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query"
import React from "react"
import { PriceDto, ProductDto, ResponseWorkDto, WorkDto } from "src/api/generated"
import { createWork } from "src/api/mutations/createWork"
import { updateWork } from "src/api/mutations/updateWork"
import { getProducts } from "src/api/queries/getProducts"
import { useCustomSnack } from "src/hooks/useCustomSnack"
import CustomInputDate from "../FormInputs/CustomInputDate"
import CustomInputField from "../FormInputs/CustomInputField"
import ProductPriceSelector from "../FormInputs/ProductPriceSelector"
import ProductSelector from "../FormInputs/ProductSelector"
import SsidInput from "../FormInputs/SsidInput"

interface Props {
  /**
   * "view": show only the existing work. The form will be disabled.
   * "create": show the form to create a new work. The form will be enabled.
   * "edit": show the form to edit an existing work. The form will be enabled.
   */
  mode: "view" | "edit" | "create"
  onSuccess?: () => void
  onError?: () => void
  existingWork?: ResponseWorkDto
}

const WorkForm = (props: Props) => {
  const [date, setDate] = React.useState<string | undefined>()
  const [product, setProduct] = React.useState<ProductDto | undefined>()
  const [price, setPrice] = React.useState<PriceDto | undefined>()
  const [ssid, setSsid] = React.useState<string | undefined>()
  const [io, setIo] = React.useState<string | undefined>()
  const [priceVolume, setPriceVolume] = React.useState<number | undefined>()
  const [totalPrice, setTotalPrice] = React.useState<number | undefined>()
  const [description, setDescription] = React.useState<string | undefined>()

  const [inErrorMode, setInErrorMode] = React.useState(false)

  const createWorkMutation = useMutation(createWork)
  const updateWorkMutation = useMutation(updateWork)

  const { showSnack } = useCustomSnack()
  const queryClient = useQueryClient()
  const productsQuery = useQuery<ProductDto[], Error>(["getProducts"], getProducts)

  // load any existing workToEdit into the form
  React.useEffect(() => {
    if (!props.existingWork) return

    setDate(props.existingWork.dateOfDelivery)

    var foundProduct = productsQuery.data?.find((p) => p.id === props.existingWork?.productId)
    setProduct(foundProduct)

    var isVolumePrice = (props.existingWork?.priceVolume || 0) > 1
    setPrice(foundProduct?.prices?.find((price) => price.isVolumePrice === isVolumePrice))
    setSsid(props.existingWork.serviceSubscriptionId)
    setIo(foundProduct?.infrastructureOwnerName)
    setPriceVolume(props.existingWork.priceVolume)
    setTotalPrice(props.existingWork.totalPrice)
    setDescription(props.existingWork.description)
  }, [productsQuery.data, props.existingWork])

  const isValid = !!date && !!product && !!ssid && !!io && !!priceVolume && !!totalPrice
  const isLoading = createWorkMutation.isLoading || updateWorkMutation.isLoading
  const isDisabledSubmit = isLoading || (inErrorMode && !isValid)

  const handleSubmit = () => {
    if (!isValid) {
      setInErrorMode(true)
      return
    }

    var workDto: WorkDto = {
      serviceSubscriptionId: ssid,
      productId: product.id,
      dateOfDelivery: date,
      totalPrice: totalPrice,
      priceVolume: priceVolume,
      description: description || "",
    }

    const resetForm = () => {
      setDate(undefined)
      setPrice(undefined)
      setProduct(undefined)
      setSsid(undefined)
      setIo(undefined)
      setPriceVolume(undefined)
      setTotalPrice(undefined)
      setDescription(undefined)
      setInErrorMode(false)
    }

    // this will make the query for the table run again and fetch data anew
    const invalidateWorksCache = () => queryClient.invalidateQueries(["getWork"])

    if (props.mode === "edit" && props.existingWork) {
      updateWorkMutation.mutate(
        { id: props.existingWork.id, body: workDto },
        {
          onSuccess: () => {
            invalidateWorksCache()
            showSnack("Linjen blev opdateret!", "success")
            props.onSuccess && props.onSuccess()
          },
          onError: (error) => {
            showSnack((error as Error).message, "error")
          },
        }
      )
    } else if (props.mode === "create") {
      createWorkMutation.mutate(workDto, {
        onSuccess: () => {
          resetForm()
          invalidateWorksCache()
          showSnack("Linjen blev oprettet!", "success")
          props.onSuccess && props.onSuccess()
        },
        onError: (error) => {
          showSnack((error as Error).message, "error")
        },
      })
    } else {
      throw new Error("Invalid mode")
    }
  }

  return (
    <Container>
      <GridContainerFirstRow>
        <Grid container columnSpacing={{ xs: 4 }}>
          <Grid item xs={3}>
            <SsidInput
              onIOChangeHandler={setIo}
              onSsidChangeHandler={(newVal) => setSsid(newVal?.trim())}
              ssid={ssid}
              io={io}
              triggerRequiredError={inErrorMode}
              disabled={props.mode === "view"}
            />
          </Grid>

          <Grid item xs={3}>
            <ProductSelector
              onChangeHandler={(value) => {
                setProduct(value)
                setPrice(undefined)
              }}
              infrastructureOwner={io}
              triggerRequiredError={inErrorMode}
              value={product}
              disabled={props.mode === "view"}
            />
          </Grid>

          <Grid item xs={3}>
            <CustomInputField
              label="Transaktionskode"
              name="transaction-code"
              disabled
              required
              invalidText="Transaktionskode påkrævet"
              value={product?.transactionCode}
              triggerRequiredError={inErrorMode}
            />
          </Grid>

          <Grid item xs={3}>
            <ProductPriceSelector
              triggerRequiredError={inErrorMode}
              product={product}
              value={price}
              disabled={props.mode === "view"}
              onChangeHandler={(value) => {
                setPrice(value)
                setTotalPrice(value?.value)
                if (value?.isVolumePrice === false || priceVolume === undefined) setPriceVolume(1)
              }}
            />
          </Grid>
        </Grid>
      </GridContainerFirstRow>
      <Grid container rowSpacing={1} columnSpacing={{ xs: 4 }}>
        <Grid item xs={3}>
          <CustomInputDate
            label="Dato"
            required
            name="date"
            disabled={props.mode === "view"}
            onChangeHandler={(value) => setDate(value)}
            invalidText="Dato påkrævet"
            value={date}
            triggerRequiredError={inErrorMode}
          />
        </Grid>

        <Grid item xs={3}>
          <CustomInputField
            type="number"
            name="price-volume"
            label="Antal enheder"
            disabled={props.mode === "view" || price?.isVolumePrice === false}
            required
            onChangeHandler={(value) => {
              var valueParsed = value ? parseInt(value) : undefined
              setPriceVolume(valueParsed)
              var totalPriceTmp =
                valueParsed !== undefined && price?.value !== undefined
                  ? valueParsed * price.value
                  : undefined
              setTotalPrice(totalPriceTmp)
            }}
            value={priceVolume?.toString()}
            invalidText="Skal være større end 0"
            regex={/^[1-9]\d*$/}
            triggerRequiredError={inErrorMode}
          />
        </Grid>

        <Grid item xs={3}>
          <CustomInputField
            type="number"
            name="total-price"
            label="Samlet pris"
            disabled={props.mode === "view"}
            required
            onChangeHandler={(value) => setTotalPrice(value ? parseFloat(value) : undefined)}
            value={totalPrice?.toString()}
            invalidText="Skal være større end 0"
            triggerRequiredError={inErrorMode}
            minValue={1}
          />
        </Grid>
        <Grid item xs={3}></Grid>
        <Grid item xs={9}>
          <CustomInputField
            type="text"
            name="description"
            label="Beskrivelse"
            disabled={props.mode === "view"}
            required={false}
            regex={/^.{0,80}$/}
            onChangeHandler={setDescription}
            triggerRequiredError={inErrorMode}
            invalidText="Skal være mindre end 80 tegn"
            value={description}
          />
        </Grid>
        <Grid item xs={3}>
          {props.mode !== "view" && (
            <StyledLoadingButton
              onClick={handleSubmit}
              data-testid="submit-button"
              loading={isLoading}
              disabled={isDisabledSubmit}
              loadingPosition="start"
              startIcon={<SaveIcon />}
              variant="contained"
              color="secondary"
            >
              Gem og luk
            </StyledLoadingButton>
          )}
        </Grid>
      </Grid>
    </Container>
  )
}
export default WorkForm

const Container = styled("div")`
  width: 100%;
`

const GridContainerFirstRow = styled("div")`
  margin-bottom: ${(props) => props.theme.spacing(3)};
  border-bottom: 2px dashed ${(props) => props.theme.palette.divider};
  padding-bottom: ${(props) => props.theme.spacing(1)};
`
const StyledLoadingButton = styled(LoadingButton)`
  float: right;
  padding-top: 14px;
  padding-bottom: 14px;
`
